<template>
  <div class="toast show"
       :id="'taskID_' + task.id"
       :style="'background:' + timeStatusColor">
    <div class="toast-body">
      <task-item-main-block :task="task"
                            :current_background="task.type_state.custom_color.replace('0.3', '0.1')"
      />

      <task-item-comment-block v-if="task.type_id === 1 && task.last_comment"
                               :task_last_comment="task.last_comment" />

      <task-item-client-block v-if="task.client" :task_client="task.client" :task_id="task.id" :is_called="task.details.is_called ?1:0"/>

      <task-item-car-block v-if="task.car" :task_car="task.car" />

      <task-item-term-block :task_term_date="task.term_date"
                            :task_term_time="task.term_time"
                            :task_term_label="task.term_label"
                            :task_updated_date="task.updated_date"
                            :task_type_id="task.type_id"
                            :task_type_state_id="task.type_state_id"
                            @set-time-status-color="handleTimeStatusColor"/>

      <task-item-branch-block v-if="task.branch || task.department"
                              :task_branch="task.branch"
                              :task_department="task.department"/>

      <task-item-responsible-block :task_responsible="task.responsible" />

      <!--      <task-item-creator-block :task_creator="(task.creator ? task.creator : task.client)"-->
      <!--                               :task_updated_date="task.updated_date"/>-->

      <task-item-details-block v-if="Object.keys(task.details).length"
                               :task_details="task.details"
                               :task_car_price="task.car ? task.car.price : 0"
                               :with_delimiter="false" />

      <task-item-comment-block v-if="task.type_id !== 1 && task.last_comment"
                               :task_last_comment="task.last_comment"
                               :with_delimiter="false" />
    </div>

    <div class="toast-header" :style="'background:' + task.type_state.custom_color.replace('0.25', '0.1')">
      <button v-if="((authUser.id === task.responsible_id) ? true : ([1, 2, 7, 8, 9].includes(authUser.role.id)))"
              class="btn btn-primary"
              title="Редактировать"
              type="button"
              v-on:click="() => {
                EventBus.$emit('open-edit-task-modal', {
                  task: task,
                });
              }">
        <i class="fa fa-edit"></i>
      </button>

      <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'История' }}"
                   class="btn btn-success margin-l-5"
                   title="Детали"
                   type="button">
        <i class="fa fa-info-circle"></i>
      </router-link>

      <button v-if="((authUser.id === task.responsible_id) ? (![2].includes(authUser.role.id) || ([1, 7, 8, 9].includes(authUser.role.id))) : ([1, 2, 7, 8, 9].includes(authUser.role.id)))"
              class="btn btn-warning margin-l-5"
              title="В архив"
              type="button"
              v-on:click="removeTask(task.id)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

Vue.component("task-item-main-block", (resolve) => {
  return require(["./../item_blocks/main-block"], resolve);
});
Vue.component("task-item-comment-block", (resolve) => {
  return require(["./../item_blocks/comment-block"], resolve);
});
Vue.component("task-item-client-block", (resolve) => {
  return require(["./../item_blocks/client-block"], resolve);
});
Vue.component("task-item-car-block", (resolve) => {
  return require(["./../item_blocks/car-block"], resolve);
});
Vue.component("task-item-branch-block", (resolve) => {
  return require(["./../item_blocks/branch-block"], resolve);
});
Vue.component("task-item-term-block", (resolve) => {
  return require(["./../item_blocks/term-block"], resolve);
});
Vue.component("task-item-responsible-block", (resolve) => {
  return require(["./../item_blocks/responsible-block"], resolve);
});
// Vue.component('task-item-creator-block', (resolve) => {
//   return require(['./../item_blocks/creator-block'], resolve);
// });
Vue.component("task-item-details-block", (resolve) => {
  return require(["./../item_blocks/details-block"], resolve);
});

export default {
  name: "funnel-task-item",

  props: {
    task: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  data() {
    return {
      timeStatusColor: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  methods: {
    handleTimeStatusColor(value) {
      this.timeStatusColor = value;
    },
    removeTask(taskId) {
      this.$dialog.confirm("Вы уверены что хотите удалить запись?", this.$dialogOptions).then(() => {
        API.apiClient.delete("/tasks/" + taskId).then(() => {
          setTimeout(() => {
            document.getElementById("taskID_" + taskId).style.display = "none";
          }, 500);
          this.EventBus.$emit("tasks-statistic-reload");
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  },
};
</script>

<style scoped>
.btn, a {
  white-space: break-spaces;
}

.toast {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  width: unset;
  box-shadow: none;
  margin-bottom: 15px;
}

.toast-body {
  padding: 8px 8px 10px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.toast-header {
  border-radius: 0;
  border-bottom: 0;
  padding: 8px;
}

.toast-header:hover {
  background: #f4f4f6;
}
</style>
